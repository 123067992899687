import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import data from "../../assets/scripts/scripts.jsx";
import { useLanguage } from "../../assets/translations/Traslations.jsx";
const { IconSocialMedia, FooterData } = data;

export default function Footer() {
  const { language } = useLanguage();

  const traductions = (data) => {
    return data[language];
  };

  const navigate = useNavigate();

  const handleClick = (path) => () => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <footer className="footer">
      <Container fluid={true} className="container-footer">
        <Row>
          <Col xs={12} md={1} className="ColumnLogoFooter">
            <img
              onClick={handleClick("/")}
              src={IconSocialMedia.Logo}
              alt="Disex_Logo"
              className="footer-logo"
            />
          </Col>
          <Col xs={12} md={4} className="ColumnTextFooter">
            <p className="copyright">
              &copy; {new Date().getFullYear()} {traductions(FooterData).tittle}
            </p>
          </Col>
          <Col
            xs={12}
            md={2}
            className="ColumnTextFooter"
            onClick={handleClick("/PTEE-LAFT")}
          >
            <p className="privacy-policy">
              {" "}
              {traductions(FooterData).policies}
            </p>
          </Col>
          <Col
            xs={12}
            md={3}
            className="ColumnTextFooter"
            onClick={handleClick("/t-y-c")}
          >
            <p className="terms-conditions">
              {traductions(FooterData).termsandconditions}
            </p>
          </Col>

          <Col xs={6} md={1} className="ColumnTextFooter">
            <a href="https://co.linkedin.com/company/dise%C3%B1os-exclusivos-s-a-s">
              <img
                src={IconSocialMedia.LinkedinIconMin}
                className="social-icon"
                alt="Linkedin Icon"
              />
            </a>
          </Col>
          <Col xs={6} md={1} className="ColumnTextFooter">
            <a href="https://www.instagram.com/disex.co/">
              <img
                src={IconSocialMedia.InstagramIconMin}
                className="social-icon"
                alt="Instagram Icon"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
