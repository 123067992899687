// Scritps Generales principal

//Images ORDENADOR
import FondoDivTextil from "../media/PC/bgHilosHomePC.jpg";
import BgHilos from "../media/PC/bgHilosHomePC.jpg";
import LogoDisex from "../media/PC/LOGO_DISEX2.png";
import MaqTej from "../media/PC/bgContacto.jpg";
import Disexbg from "../media/PC/Disexbg.jpg";
import DisenoPC from "../media/PC/DiseñoPC.jpg";
import MaquinaTela from "../media/PC/MaquinaTela.jpg";
import MaqTej2 from "../media/PC/bgMaquinaTejHomePC.jpg";
import BgPaqC from "../media/PC/bgHomePaqCompletoPC.jpg";

// IMAGENES CEL

// Miniaturas Telas
import TelaVancouver from "../media/PC/TelaVancouverPcMin.jpg";
import TelaRoja from "../media/PC/TelaRojaPcMin.jpg";
import RIBS from "../media/PC/RIB-Min.jpg";
import PoliAlgodon from "../media/PC/POLIESTER-ALGODON-Min.jpg";
import PoliNylon from "../media/PC/POLIESTER-NYLON-Min.jpg";
// import TelaFucsia from "../media/PC/TelaFucsiaPcMin.jpg";
import TelaRayas from "../media/PC/TelaRayasPcMin.jpg";
// import TelaVerde from "../media/PC/TelaVerdePc.jpg";

import TelaRosa from "../media/PC/TelaRosaPc.jpg";
import TelaRosa2 from "../media/PC/DSC02460.jpg";
import TelaMorada from "../media/PC/DSC02669.jpg";

//Miniaturas Imgs
import bgRopero from "../media/PC/RoperoBgMin.jpg";
import MaqTejMin from "../media/PC/MaqTejMin.jpg";
import MaqTejMin2 from "../media/PC/MaqTejMin2.jpg";
import HilosMin from "../media/PC/HilosMin.jpg";
import TelasMin from "../media/PC/TelasMin.jpg";
import CorteTelaMin from "../media/PC/CorteTelaMin.jpg";

//Icons
import InnovateIcon from "../icons/InnovateIcon.svg";
import CallIcon from "../icons/CallIcon.svg";
import CorreoIcon from "../icons/CorreoIcon.svg";
import NatureIcon from "../icons/NatureIcon.svg";
import LabIcon from "../icons/LabIcon.svg";
import AcabadosIcon from "../icons/AcabadosIcon.svg";
import PatronajeIcon from "../icons/PatronajeIcon.svg";
import TeamIcon from "../icons/TeamIcon.svg";
import LinkedinIcon from "../icons/LinkedinIcon.svg";
import InstagramIcon from "../icons/InstagramIcon.svg";
import LinkedinIconMin from "../icons/LinkedinIconMin.svg";
import InstagramIconMin from "../icons/InstagramIconMin.svg";
import LocationIcon from "../icons/LocationPin.svg";
import FollowIcon from "../icons/FollowIcon.svg";
import ContactIcon from "../icons/ContactIcon.svg";
import DevelopmentIcon from "../icons/DevelopmentIcon.svg";
import ProcessIcon from "../icons/ProcessIcon.svg";
import QualityIcon from "../icons/QualityIcon.svg";
import TruckIcon from "../icons/TruckIcon.svg";

//SVG grandes
import Mantenimiento from "../icons/Mantenimiento.svg";

//Contenidos para el HOME
// const ContHomeHeader = {
//   Banner: TelaRosa2,
//   Texto: "Tejemos sueños, cosemos excelencia.",
//   Subtitulo:
//     "En Disex te ayudamos y respaldamos en todos tus procesos creativos y productivos",
//   TextButton1: "Paquete Completo",
//   TextButton2: "División Textil",
//   Button1Classname: "button-transparent-right",
//   Button2Classname: "button-transparent-left",
//   Navigate1: "/PaqueteCompleto",
//   Navigate2: "/DivisionTextil",
// };

const NavbarData = {
  es: {
    disex: "DISEX",
    navigate: "/",
    home: "Inicio",
    services: "Servicios",
    fullPackage: "Paquete Completo",
    navigate1: "/paquete-completo",
    textileDivision: "División Textil",
    navigate2: "/division-textil",
    aboutUs: "Nosotros",
    navigate3: "/nosotros",
    contact: "Contacto",
    navigate4: "/contacto",
  },
  en: {
    disex: "DISEX",
    navigate: "/",
    home: "Home",
    services: "Services",
    fullPackage: "Full Package",
    navigate1: "/full-package",
    textileDivision: "Textile Division",
    navigate2: "/textile-division",
    aboutUs: "About Us",
    navigate3: "/about-us",
    contact: "Contact",
    navigate4: "/contact",
  },
};

const ContHomeHeader = {
  es: {
    Banner: TelaRosa2,
    Texto: "Tejemos sueños, cosemos excelencia.",
    Subtitulo:
      "En Disex te ayudamos y respaldamos en todos tus procesos creativos y productivos",
    TextButton1: "Paquete Completo",
    TextButton2: "División Textil",
    Button1Classname: "button-transparent-right",
    Button2Classname: "button-transparent-left",
    Navigate1: "/paquete-completo",
    Navigate2: "/division-textil",
  },
  en: {
    Banner: TelaRosa2,
    Texto: "We weave dreams, we sew excellence.",
    Subtitulo:
      "In Disex we support you in your creative and production process",
    TextButton1: "Full Package",
    TextButton2: "Textile Division",
    Button1Classname: "button-transparent-right",
    Button2Classname: "button-transparent-left",
    Navigate1: "/full-package",
    Navigate2: "/textile-division",
  },
};

// const ContHomeSection1 = {
//   Bg: Disexbg,
//   Tittle: "Somos DISEX",
//   Parrafo:
//     "Somos DISEÑOS EXCLUSIVOS SAS, y por más de 45 años hemos ayudado a marcas de moda a desarrollar y hacer realidad sus colecciones.",
//   Button1: {
//     text: "Conócenos",
//     navigateto: "/Nosotros",
//     classname: "button-transparent-section1Home",
//   },
// };
const ContHomeSection1 = {
  es: {
    bg: Disexbg,
    tittle: "Somos DISEX",
    parrafo:
      "Somos DISEÑOS EXCLUSIVOS SAS y por más de 45 años hemos ayudado a marcas de moda a desarrollar y hacer realidad sus colecciones.",
    button1: {
      text: "Conócenos",
      navigateto: "/Nosotros",
      classname: "button-transparent-section1Home",
    },
  },
  en: {
    bg: Disexbg,
    tittle: "We are DISEX",
    parrafo:
      "We are DISEÑOS EXCLUSIVOS SAS and for over 45 years we have been helping fashion brands to develop and bring their collections to life.",
    button1: {
      text: "Learn More",
      navigateto: "/AboutUs",
      classname: "button-transparent-section1Home",
    },
  },
};
const ContHomeSection2 = {
  es: {
    bgDivT: TelaMorada,
    bgPaqC: BgPaqC,
    tittle1: "División Textil",
    tittle2: "Paquete Completo",
    button1: {
      text: "Quiero más información",
      navigateto: "/division-textil",
      classname: "button-transparent-section2Home-left",
    },
    button2: {
      text: "Quiero más información",
      navigateto: "/paquete-completo",
      classname: "button-transparent-section2Home-left",
    },
  },

  en: {
    bgDivT: TelaMorada,
    bgPaqC: BgPaqC,
    tittle1: "Textile Division",
    tittle2: "Complete Package",
    button1: {
      text: "More information",
      navigateto: "/textile-division",
      classname: "button-transparent-section2Home-left",
    },
    button2: {
      text: "More information",
      navigateto: "/full-package",
      classname: "button-transparent-section2Home-left",
    },
  },
};

const ContHomeSection3 = {
  es: {
    bg: MaqTej2,
    tittle: "¿Qué nos hace diferentes?",
    paragraph: "Entendemos y nos adaptamos al ADN de cada cliente",
    contInfoCards: {
      card1: {
        contentFront: "Integración vertical",
        contentBack:
          "Capacidad productiva propia para prestar un servicio completo que comprende desde la creación de textiles en tejido de punto hasta el producto final.",
        icon: InnovateIcon,
      },
      card2: {
        contentFront: "Respaldo profesional",
        contentBack:
          "Contamos con un equipo multidisciplinario y experto que entiende el ADN propio de cada marca que acompaña y respalda en cada momento creativo y productivo.",
        icon: TeamIcon,
      },
      card3: {
        contentFront: "Acabados",
        contentBack:
          "Realizamos acabados diferenciadores en telas y prendas, que  le dan un agregado extra y singularizacion a tods tus diseños.",
        icon: AcabadosIcon,
      },
      card4: {
        contentFront: "DISEX ECO",
        contentBack:
          "Textiles de algodón orgánico, poliéster y PET reciclado con certificaciones OEKO-TEX y GRS ahorran por tonelada: 7,764 m³ de agua, 28 MWh de energía, una tonelada de químicos, 11 toneladas de CO₂ y 19,740 botellas plásticas",
        icon: NatureIcon,
      },
      card5: {
        contentFront: "Especializados en diseño y patronaje",
        contentBack:
          "Décadas de experiencia creando diseños y patrones impecables.",
        icon: PatronajeIcon,
      },
      card6: {
        contentFront: "Laboatorio de color",
        contentBack:
          "Desarrollamos el color según tu pantone elegido y lo hacemos realidad en las bases textiles y prendas. ",
        icon: LabIcon,
      },
    },
  },
  en: {
    tittle: "What makes us different?",
    paragraph: "We understand and adapt to each client's DNA.",
    bg: MaqTej2,
    contInfoCards: {
      card1: {
        contentFront: "Vertical Integration",
        contentBack:
          "We have the production capabilities to offer a full-service solution, from creating knit fabrics to delivering the final product.",
        icon: InnovateIcon,
      },
      card2: {
        contentFront: "Professional Support",
        contentBack:
          "Our multidisciplinary expert team understands the unique DNA of each brand, providing support through every stage of the creative and production process.",
        icon: TeamIcon,
      },
      card3: {
        contentFront: "Finishes",
        contentBack:
          "We create unique finishes for fabrics and garments, adding extra value and singularity to all your designs.",
        icon: AcabadosIcon,
      },
      card4: {
        contentFront: "DISEX ECO",
        contentBack:
          "Organic cotton, polyester, and recycled PET textiles with OEKO-TEX and GRS certifications save per ton: 7,764 m³ of water, 28 MWh of energy, one ton of chemicals, 11 tons of CO₂, and 19,740 plastic bottles.",
        icon: NatureIcon,
      },
      card5: {
        contentFront: "Experts in Design and Patternmaking",
        contentBack:
          "Decades of experience creating flawless designs and patterns.",
        icon: PatronajeIcon,
      },
      card6: {
        contentFront: "Color Lab",
        contentBack:
          "We develop colors based on your chosen Pantone and bring them to life in textile bases and garments.",
        icon: LabIcon,
      },
    },
  },
};

// const ContHomeSection2 = {
//   es: {
//     BgDivT: TelaMorada,
//     BgPaqC: BgPaqC,
//   },
//   en: {
//     BgDivT: TelaMorada,
//     BgPaqC: BgPaqC,
//   },
// };

// const ContHomeSection3 = {
//   es: {
//     Tittle: "¿Qué nos hace diferentes?",
//     Parrafo: "Entendemos y nos adaptamos al ADN de cada cliente",
//     Bg: MaqTej2,
//     ContInfoCards: {
//       Card1: {
//         contentFront: "Integración vertical",
//         contentBack:
//           "Capacidad productiva propia para prestar un servicio completo que comprende desde la creación de textiles en tejido de punto hasta el producto final.",
//         icon: InnovateIcon,
//       },
//       Card2: {
//         contentFront: "Respaldo profesional",
//         contentBack:
//           "Contamos con un equipo multidisciplinario y experto que entiende el ADN propio de cada marca que acompaña y respalda en cada momento creativo y productivo.",
//         icon: TeamIcon,
//       },
//       Card3: {
//         contentFront: "Acabados",
//         contentBack:
//           "Realizamos acabados diferenciadores en telas y prendas, que le dan un agregado extra y singularización a todos tus diseños.",
//         icon: AcabadosIcon,
//       },
//       Card4: {
//         contentFront: "DISEX ECO",
//         contentBack:
//           "Textiles de algodón orgánico, poliéster y PET reciclado con certificaciones OEKO-TEX y GRS ahorran por tonelada: 7,764 m³ de agua, 28 MWh de energía, una tonelada de químicos, 11 toneladas de CO₂ y 19,740 botellas plásticas.",
//         icon: NatureIcon,
//       },
//       Card5: {
//         contentFront: "Especializados en diseño y patronaje",
//         contentBack:
//           "Décadas de experiencia creando diseños y patrones impecables.",
//         icon: PatronajeIcon,
//       },
//       Card6: {
//         contentFront: "Laboratorio de color",
//         contentBack:
//           "Desarrollamos el color según tu pantone elegido y lo hacemos realidad en las bases textiles y prendas.",
//         icon: LabIcon,
//       },
//     },
//   },
//   en: {
//     Tittle: "What makes us different?",
//     Parrafo: "We understand and adapt to each client's DNA.",
//     Bg: MaqTej2,
//     ContInfoCards: {
//       Card1: {
//         contentFront: "Vertical integration",
//         contentBack:
//           "Own productive capacity to provide a comprehensive service that includes everything from the creation of knitted textiles to the final product.",
//         icon: InnovateIcon,
//       },
//       Card2: {
//         contentFront: "Professional support",
//         contentBack:
//           "We have a multidisciplinary and expert team that understands the unique DNA of each brand and supports them at every creative and productive stage.",
//         icon: TeamIcon,
//       },
//       Card3: {
//         contentFront: "Finishes",
//         contentBack:
//           "We create unique finishes on fabrics and garments, adding an extra touch to all your designs.",
//         icon: AcabadosIcon,
//       },
//       Card4: {
//         contentFront: "DISEX ECO",
//         contentBack:
//           "Organic cotton, recycled polyester, and PET textiles certified by OEKO-TEX and GRS save per ton: 7,764 m³ of water, 28 MWh of energy, one ton of chemicals, 11 tons of CO₂, and 19,740 plastic bottles.",
//         icon: NatureIcon,
//       },
//       Card5: {
//         contentFront: "Specialized in design and pattern making",
//         contentBack:
//           "Decades of experience creating impeccable designs and patterns.",
//         icon: PatronajeIcon,
//       },
//       Card6: {
//         contentFront: "Color laboratory",
//         contentBack:
//           "We develop the color according to your chosen Pantone and bring it to life on textile bases and garments.",
//         icon: LabIcon,
//       },
//     },
//   },
// };

const ContHomeSection4 = {
  fondo: BgHilos,
};

//----------------------------CONTENIDO PARA LA DIVISION TEXTIL------------------

const BgDivTextInfoContact = {
  fondo: TelaRoja,
};

const IconForms = {
  Icon1: CallIcon,
  Icon2: CorreoIcon,
  Icon3: LocationIcon,
};

const HeaderDivTextil = {
  es: {
    Banner: FondoDivTextil,
    Text: "División Textil",
    Subtittle:
      "En Disex contamos con un amplio portafolio de telas en tejido de punto diferenciadas en sus texturas, materiales y acabados funcionales con altos estándares de investigación, diseño e innovación.",
    // Descripcion:
    //   "En Disex contamos con un amplio portafolio de telas en tejido de punto diferenciadas en sus texturas, materiales y acabados funcionales con altos estándares de investigación, diseño e innovación.",
    TextButton1: "Contáctanos",
    Navigate1: "/Contacto",
    Button1Classname: "button-transparent-general-center",
    Button2Classname: "button-null",
  },

  en: {
    Banner: FondoDivTextil,
    Text: "Textile Division",
    Subtittle:
      "In Disex, we have a wide range of knit fabrics differentiated by their textures, materials, and functional finishes with high standards of research, design, and innovation.",
    // Descripcion:
    //   "At Disex, we have a wide range of knit fabrics differentiated by their textures, materials, and functional finishes with high standards of research, design, and innovation.",
    TextButton1: "Contact Us",
    Navigate1: "/Contact",
    Button1Classname: "button-transparent-general-center",
    Button2Classname: "button-null",
  },
};

const Section1DivTextil = {
  es: {
    tittle: "Algunas de nuestras bases textiles",
    subtittle: "Conoce más sobre nuestras telas",
    navigate: "/Contacto",
    Card1: {
      TelaBg: TelaVancouver,
      NombreTela: "BURDAS",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },
    Card2: {
      TelaBg: PoliNylon,
      NombreTela: "POLIÉSTER/NYLON",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },
    Card3: {
      TelaBg: TelaRosa,
      NombreTela: "ALGODÓN",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },
    Card4: {
      TelaBg: RIBS,
      NombreTela: "RIB",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },
    Card5: {
      TelaBg: TelaRayas,
      NombreTela: "VISCOSA",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },
    Card6: {
      TelaBg: PoliAlgodon,
      NombreTela: "POLIESTER ALGODÓN",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },
  },

  en: {
    tittle: "Some of our textile bases",
    subtittle: "Learn more",
    navigate: "/Contact",
    Card1: {
      TelaBg: TelaVancouver,
      NombreTela: "COARSE",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },
    Card2: {
      TelaBg: PoliNylon,
      NombreTela: "POLYESTER/NYLON",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },

    Card3: {
      TelaBg: TelaRosa,
      NombreTela: "COTTON",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },

    Card4: {
      TelaBg: RIBS,
      NombreTela: "RIB",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },

    Card5: {
      TelaBg: TelaRayas,
      NombreTela: "VISCOSE",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },

    Card6: {
      TelaBg: PoliAlgodon,
      NombreTela: "POLYESTER COTTON",
      // Propiedades: {
      //   Composicion1: "Algodón RECICLADO 50%",
      //   Composicion2: "Algodón ORGÁNICO 50%",
      //   Ancho: "1.50 Mts",
      //   Peso: "150 grs/ml",
      //   Rendimiento: "4.4",
      // },
    },
  },
};

const Section2DivTextil = {
  Img: PoliAlgodon,
  es: {
    tittle: "Nuestras Capacidades",
    // Row1: {
    //   col1: {
    //     img: bgRopero,
    //     text: "Prendas: 250.000 mensuales.",
    //   },
    //   col2: {
    //     img: TelasMin,
    //     text: "Telas: 130 toneladas mensuales.",
    //   },
    //   col3: {
    //     img: MaqTejMin,
    //     text: "25 máquinas circulares de tejido de punto.",
    //   },
    //   col4: {
    //     img: CorteTelaMin,
    //     text: "Corte automatizado",
    //   },
    // },
    Row2: {
      col1: {
        img: bgRopero,
        text: "Telas: 130 toneladas mensuales.",
      },
      col2: {
        img: MaqTejMin,
        text: "25 máquinas circulares de tejido de punto.",
      },
      col3: {
        img: HilosMin,
        text: "200 empresas aliadas para el proceso de manufactura.",
      },
      col4: {
        img: MaqTejMin2,
        text: "4.102 metros cuadrados de planta de producción.",
      },
    },
  },
  en: {
    tittle: "Our Capabilitiess",
    // Row1: {
    //   col1: {
    //     img: bgRopero,
    //     text: "Garments: 250,000 per month.",
    //   },
    //   col2: {
    //     img: TelasMin,
    //     text: "Fabrics: 130 tons per month.",
    //   },
    //   col3: {
    //     img: MaqTejMin,
    //     text: "25 circular knitting machines.",
    //   },
    //   col4: {
    //     img: CorteTelaMin,
    //     text: "Automated cutting.",
    //   },
    // },
    Row2: {
      col1: {
        img: bgRopero,
        text: "Fabrics: 130 tons per month.",
      },
      col2: {
        img: MaqTejMin,
        text: "25 circular knitting machines.",
      },
      col3: {
        img: HilosMin,
        text: "200 partner companies in the manufacturing process.",
      },
      col4: {
        img: MaqTejMin2,
        text: "4,102 square meters of production plant.",
      },
    },
  },
};

const Section3DivTextil = {
  // Img: MaqTej2,
  // Tittle: "Algunos de nuestros clientes",
};

//--------------------------CONTENIDO PARA EL PAQUETE COMPLETO---------------------------

const HeaderPaqueteCompleto = {
  es: {
    Banner: DisenoPC,
    Texto: "PAQUETE COMPLETO",
    Subtitulo: "",
    Descripcion:
      "En Disex te ayudamos a desarrollar tus ideas de marca, desde el entendimiento de tu ADN de marca, diseño, patronaje especializado, desarrollo de muestras, producción, revisión de calidad y logística a cualquier parte del mundo.",
    TextButton1: "Contáctanos",
    TextButton2: "DIVISIÓN TEXTIL",
    Navigate1: "/contacto",
    Button1Classname: "button-transparent-general-center",
    Button2Classname: "button-null",
  },
  en: {
    Banner: DisenoPC,
    Texto: "FULL PACKAGE",
    Subtitulo: "",
    Descripcion:
      "In Disex help you to develop your brand ideas understanding your  DNA. we are experts in design, specialized pattern-making, sample development, production, quality control, and logistics.",
    TextButton1: "Contact us",
    TextButton2: "TEXTILE DIVISION",
    Navigate1: "/contact",
    Button1Classname: "button-transparent-general-center",
    Button2Classname: "button-null",
  },
};

const ContSection1PaqCom = {
  bg: BgPaqC,
};

const Tittlesection1PC = {
  es: {
    tittle: "¿Cómo lo hacemos?",
  },
  en: {
    tittle: "How do we do it?",
  },
};

const TimelineData = [
  {
    es: {
      Num: "1",
      Content: "Contacta con nosotros y cuéntanos acerca de tu marca.",
      Icon: ContactIcon,
    },
    en: {
      Num: "1",
      Content: "Contact us and tell us about your brand.",
      Icon: ContactIcon,
    },
  },
  {
    es: {
      Num: "2",
      Content:
        "El área de Diseño y desarrollo se encarga de materializar tus ideas de colecciones, según el ADN de tu marca y tus requerimientos específicos.",
      Icon: DevelopmentIcon,
    },
    en: {
      Num: "2",
      Content:
        "The Design and Development team is responsible for bringing your collection ideas to life, based on your brand's DNA and specific requirements.",
      Icon: DevelopmentIcon,
    },
  },
  {
    es: {
      Num: "3",
      Content:
        "Ajustamos y desarrollamos el patronaje y fits perfectos para tu marca.",
      Icon: PatronajeIcon,
    },
    en: {
      Num: "3",
      Content:
        "We adjust and develop the perfect patterns and fits for your brand.",
      Icon: PatronajeIcon,
    },
  },
  {
    es: {
      Num: "4",
      Content:
        "Nuestro taller de muestras se convierte en tu laboratorio, en el cual volvemos realidad cada diseño que harán parte de tus colecciones.",
      Icon: LabIcon,
    },
    en: {
      Num: "4",
      Content:
        "Our sample workshop becomes your lab, where we bring to life every design that will be part of your collections.",
      Icon: LabIcon,
    },
  },
  {
    es: {
      Num: "5",
      Content:
        "La etapa de producción comprende todo lo relacionado con corte tecnificado, confección en talleres fidelizados con DISEX, acabados premium y empaque.",
      Icon: ProcessIcon,
    },
    en: {
      Num: "5",
      Content:
        "The production stage includes from advanced cutting and sewing in DISEX-trusted workshops to premium finishing and packaging.",
      Icon: ProcessIcon,
    },
  },
  {
    es: {
      Num: "6",
      Content:
        "Nuestra de área de calidad, supervisa y examina cuidadosamente cada una de las prendas en cada etapa del proceso productivo.",
      Icon: QualityIcon,
    },
    en: {
      Num: "6",
      Content:
        "Our quality team carefully supervises and examines each garment at every stage of the production process.",
      Icon: QualityIcon,
    },
  },
  {
    es: {
      Num: "7",
      Content:
        "El proceso de terminación comprende planchado, etiquetado, empaque y envío a cualquier parte del mundo.",
      Icon: TruckIcon,
    },
    en: {
      Num: "7",
      Content:
        "The finishing process includes ironing, labeling, packaging, and shipping to any part of the world.",
      Icon: TruckIcon,
    },
  },
];

const Section2PaqCom = {
  es: {
    img: PoliAlgodon,
    tittle: "Nuestras capacidades",
    row1: {
      col1: {
        img: bgRopero,
        text: "Prendas: 250.000 mensuales.",
      },
      col2: {
        img: TelasMin,
        text: "Telas: 130 toneladas mensuales.",
      },
      col3: {
        img: CorteTelaMin,
        text: "Corte automatizado",
      },
      col4: {
        img: MaqTejMin2,
        text: "Alianzas con 30 talleres de confección que aportan 200 máquinas de coser.",
      },
    },
  },
  en: {
    img: PoliAlgodon,
    tittle: "Our capabilities",
    row1: {
      col1: {
        img: bgRopero,
        text: "Garments: 250,000 per month.",
      },
      col2: {
        img: TelasMin,
        text: "Fabrics: 130 tons per month.",
      },
      col3: {
        img: CorteTelaMin,
        text: "Automated cutting",
      },
      col4: {
        img: MaqTejMin2,
        text: "Partnerships with 30 sewing workshops providing 200 sewing machines.",
      },
    },
  },
};

// ---------------------------------CONTENIDO PARA ACERCA DE NOSOTROS--------------------------
const HeaderAcercaNosotros = {
  es: {
    Banner: Disexbg,
    Text: "Acerca de Nosotros",
    // Subtittle: "¿Quienes Somos?",
    // Description:
    //   "Diseños Exclusivos es una empresa del sector textil / confección con más de 44 años de funcionamiento y verticalmente integrada, cuenta con 120 empleados directos y aproximadamente 450 indirectos; instalaciones de 4.100 metros cuadrados y orgullosamente Colombiana se ubicada en la ciudad de Medellín, cuenta con una capacidad productiva de 160.000 unidades mensuales y posibilidades de ampliación gracias a alianzas estratégicas.",
    TextButton1: "Contáctanos",
    Navigate1: "/Contacto",
    Button1Classname: "button-transparent-general-center",
    Button2Classname: "button-null",
  },
  en: {
    Banner: Disexbg,
    Text: "About us",
    // Subtittle: "Who are We?",
    // Description:
    //   "Diseños Exclusivos is a vertically integrated textile and garment company with over 44 years of experience. It employs 120 direct employees and approximately 450 indirect workers. With facilities spanning 4,100 square meters, this proudly Colombian company is based in Medellín. It has a production capacity of 160,000 units per month and potential for expansion thanks to strategic partnerships.",
    TextButton1: "Contact Us",
    Navigate1: "/Contact",
    Button1Classname: "button-transparent-general-center",
    Button2Classname: "button-null",
  },
};

const ContNosotrosSection1 = {
  es: {
    img: MaquinaTela,
    tittle: "Somos DISEX",
    P1: "Somos DISEÑOS EXCLUSIVOS SAS, una empresa del sector textil – confección con más de 45 años de funcionamiento y verticalmente integrada.",
    P2: "Contamos con 112 empleados directos y aproximadamente 450 indirectos; instalaciones de 4.100 metros cuadrados y capacidad productiva de 160.000 unidades mensuales y posibilidades de ampliación gracias a alianzas estratégicas.",
    P3: "Somos una empresa orgullosamente colombiana, ubicada en la ciudad de Medellin, epicentro de la moda y el desarrollo textil del país.",
  },
  en: {
    img: MaquinaTela,
    tittle: "We are DISEX",
    P1: "We are DISEÑOS EXCLUSIVOS SAS, a vertically integrated textile and garment company with over 45 years of experience.",
    P2: "We have 112 direct employees and approximately 450 indirect workers, facilities spanning 4,100 square meters, a production capacity of 160,000 units per month, and potential for expansion thanks to strategic partnerships.",
    P3: "We are a proudly Colombian company located in Medellín, the epicenter of fashion and textile development in the country.",
  },
};

const ContNosotrosSection2 = {
  es: {
    imgBg: MaqTej2,
    tittle: "Nuestra esencia",
    img1: MaqTej,
    P1: "Nuestra esencia reside en la búsqueda incesante de la <strong>perfección</strong>, una pasión que nos impulsa a crear productos  que no solo <strong>satisfagan</strong>, sino que superen las expectativas de nuestros <strong>clientes</strong>. Aspiramos a la <strong>excelencia</strong> en todo lo que hacemos, desde la selección de materiales hasta el diseño y la atención al cliente.",
    img2: TelaRosa2,
    P2: "Nuestro <strong>compromiso</strong> con la calidad es incansable. Nos avala una amplia <strong>experiencia</strong> en el mercado, la cual nos ha permitido desarrollar un profundo <strong>conocimiento</strong> de las necesidades y preferencias de nuestros clientes. Gracias a ello, podemos ofrecer productos y servicios de la más alta <strong>calidad</strong>, respaldados por un servicio al cliente excepcional.",
    img3: LogoDisex,
    P3: "Estamos convencidos que la búsqueda de la <strong>perfección</strong> es un viaje continuo que nos motiva a seguir mejorando día a día. Te invitamos a unirte a este viaje y descubrir un mundo donde la <strong>belleza</strong>, la <strong>inspiración</strong> y la <strong>calidad</strong> se unen para crear <strong>experiencias</strong> únicas e inolvidables.",
  },

  en: {
    imgBg: MaqTej2,
    tittle: "Our essence",
    img1: MaqTej,
    P1: "Our essence lies in the relentless pursuit of <strong>perfection</strong>, a passion that drives us to create products that not only <strong>meet</strong> but exceed our <strong>clients</strong> expectations. We strive for <strong>excellence</strong> in everything we do, from material selection to design and customer care.",
    img2: TelaRosa2,
    P2: "Our unwavering <strong>commitment</strong> to quality is backed by extensive <strong>experience</strong> in the market, which has allowed us to develop a deep <strong>understanding</strong> of our clients' needs and preferences. This enables us to offer products and services of the highest <strong>quality</strong>, supported by exceptional customer service.",
    img3: LogoDisex,
    P3: "We firmly believe that the pursuit of <strong>perfection</strong> is a continuous journey that inspires us to improve every day. We invite you to join us on this journey and discover a world where <strong>beauty</strong>, <strong>inspiration</strong>, and <strong>quality</strong> come together to create unique and unforgettable <strong>experiences</strong>.",
  },
};

const ContNosotrosSection4 = {
  Fondo: TelaRayas,
};

//--------------------------CONTENIDO PARA CONTACTO--------------------
const HeaderContacto = {
  es: {
    Banner: MaqTej,
    Texto: "¡Gracias por tu interés en DISEX!",
    Subtitulo:
      "Nos enorgullece ser una empresa textil colombiana dedicada a ofrecer productos de alta calidad y un excelente servicio al cliente. Si tienes alguna pregunta, inquietud o comentario, no dudes en ponerte en contacto con nosotros. Estamos aquí para ayudarte.",
    Button2Classname: "button-null",
    Button1Classname: "button-null",
  },

  en: {
    Banner: MaqTej,
    Texto: "Thank you for your interest in DISEX!",
    Subtitulo:
      "We are proud to be a Colombian textile company committed to providing high-quality products and excellent customer service. If you have any questions, concerns, or comments, please don't hesitate to reach out to us. We're here to help.",
    Button1Classname: "button-null",
    Button2Classname: "button-null",
  },
};

const IconSocialMedia = {
  Logo: LogoDisex,
  Follow: FollowIcon,
  LinkedinIcon: LinkedinIcon,
  InstagramIcon: InstagramIcon,
  LinkedinIconMin: LinkedinIconMin,
  InstagramIconMin: InstagramIconMin,
};

const ContContactInfo = {
  bg: MaqTej,
  es: {
    tittlesection1InfoContact: "¿Tienes alguna duda?",
    subtittlesection1InfoContact:
      "Ponte en contacto con nosotros y cuentanos como podemos ayudarte",
    parrafosection3Home: "Datos de contacto:",
    parrafosection3HomeTel: "Telefono",
    parrafosection3HomeUbi: "Ubicación",
  },

  en: {
    tittlesection1InfoContact: "Do you have any question?",
    subtittlesection1InfoContact:
      "Contact us and let us know how we can help you",
    parrafosection3Home: "Contact information:",
    parrafosection3HomeTel: "Phone",
    parrafosection3HomeUbi: "Location",
  },
};

const ContactForms = {
  es: {
    tittle: "Contáctanos",
    name: "Nombre",
    phone: "Teléfono",
    message: "Mensaje",
    button: "Enviar",
  },
  en: {
    tittle: "Contact Us",
    name: "Name",
    phone: "Phone",
    message: "Message",
    button: "Send",
  },
};

const Section3Contact = {
  es: {
    tittle: "Nuestra Sede",
  },
  en: {
    tittle: "Our Headquarters",
  },
};

//-------------------------------------CONTENIDO FOOTER--------------------------
const FooterData = {
  es: {
    tittle: "Disex. Todos los derechos reservados.",
    policies: "POLITICAS PTEE/LAFT",
    termsandconditions: "Términos y condiciones",
  },
  en: {
    tittle: "Disex. All rights reserved.",
    policies: "POLICIES PTEE/LAFT",
    termsandconditions: "Terms and conditions",
  },
};

const PTEE_LAFT = {
  es: {
    tittle: "POLITICAS PTEE/LAFT",
  },

  en: {
    tittle: "PTEE/LAFT POLICIES",
  },
};
//------------------------------------Contenido para 404-----------------------
const Cont404 = {
  es: {
    img: Mantenimiento,
    title: "Página en Construcción",
    text: "Estamos trabajando en la página, por favor regresa más tarde. Gracias.",
    button1Classname: "button-transparent-center-NotFound",
    button2Classname: "button-null",
    textButton1: "Volver al Inicio",
  },
  en: {
    img: Mantenimiento,
    title: "Page Under Construction",
    text: "We are working on the page, please come back later. Thank you.",
    button1Classname: "button-transparent-center-NotFound",
    button2Classname: "button-null",
    textButton1: "Back to Home",
  },
};

export default {
  NavbarData,
  ContHomeHeader,
  ContHomeSection1,
  ContHomeSection2,
  ContHomeSection3,
  ContHomeSection4,
  HeaderDivTextil,
  Section1DivTextil,
  Section2DivTextil,
  Section3DivTextil,
  HeaderPaqueteCompleto,
  ContSection1PaqCom,
  Tittlesection1PC,
  TimelineData,
  Section2PaqCom,
  BgDivTextInfoContact,
  HeaderAcercaNosotros,
  ContNosotrosSection1,
  ContNosotrosSection2,
  ContNosotrosSection4,
  IconForms,
  HeaderContacto,
  IconSocialMedia,
  ContContactInfo,
  Section3Contact,
  ContactForms,
  Cont404,
  FooterData,
  PTEE_LAFT,
};
