import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer.jsx";
import Home from "./Home.jsx";
import MyNavbar from "./components/Navbar/Navbar.jsx";

// Importar Vistas
import Nosotros from "./views/Nosotros/Nosotros.jsx";
import Contacto from "./views/Contacto/Contacto.jsx";
// import Showroom from "./views/Showroom/Showroom.jsx";
// import Clientes from "./views/Clientes/Clientes.jsx";
// import CardsBlog from "./components/Cards/CardsBlog/CardsBlog.jsx";
import Chat from "./components/ChatIcon/Chat.jsx";
import DivisionT from "./views/DivisionTextil/DivisionT.jsx";
import PaqueteC from "./views/PaqueteCompleto/PaqueteC.jsx";
import PTEELAFT from "./views/PTEE-LAFT/PTEE-LAFT.jsx";
import TermAndCond from "./views/TermsAndCond/TermAndCond.jsx";
// import Blog from "./views/Blog/Blog.jsx";
import NotFound from "./views/404/404NotFound.jsx";

import { useLanguage } from "./assets/translations/Traslations.jsx";
// import RoutesName from "./assets/scripts/scripts.jsx";



export default function App() {
  const RoutesName = {
    es: {
      home: "/",
      about: "/nosotros",
      contact: "/contacto",
      textileDivision: "/division-textil",
      fullPackage: "/paquete-completo",
      terms: "/t-y-c",
      notFound: "*",

    },
    en: {
      home: "/",
      about: "/about-us",
      contact: "/contact",
      textileDivision: "/textile-division",
      fullPackage: "/full-package",
      terms: "/t-y-c",
      notFound: "*",
    },
  };
  const { language } = useLanguage(); // Obtiene el idioma actual
  const currentRoutes = RoutesName[language]; // Selecciona las rutas según el idioma
  
  

  return (
    <Router>
      <MyNavbar />
      <Routes>
        <Route path={currentRoutes.home} element={<Home />} />
        {/* <Route path="/Clientes" element={<Clientes />} /> */}
        <Route path={currentRoutes.about} element={<Nosotros />} />
        {/* <Route path="/Showroom" element={<Showroom />} /> */}
        <Route path={currentRoutes.contact} element={<Contacto />} />
        <Route path={currentRoutes.textileDivision} element={<DivisionT />} />
        <Route path={currentRoutes.fullPackage} element={<PaqueteC />} />
        <Route path={currentRoutes.terms} element={<TermAndCond />} />
        <Route path="/PTEE-LAFT" element={<PTEELAFT />} />
        {/* <Route path="/Blog" element={<Blog />} /> */}
        <Route path={currentRoutes.notFound} element={<NotFound />} />
        {/* <Route path="*" element={<Navigate to="/404" />} /> */}
      </Routes>
      <Chat />
      <Footer />
    </Router>
  );
}
