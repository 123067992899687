import React from "react";
import "./CardsHover.css";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { useLanguage } from "../../../assets/translations/Traslations";


export default function CardsHover({ Img, Tittle,subtittle,navigateto}) {

  const { language } = useLanguage();

  const navigate = useNavigate();
  // const translations = (data) => {
  //   return data[language];
  // }

  const handleOnclick = () => {
    {language === "es" ? navigate(navigateto) : navigate(navigateto)}
  }

  return (
    <Container className="columnas-figure" onClick={handleOnclick}>
      <div className="figure">
        <img className="img-card-hover" src={Img} alt="ImgCardHover" />
        <div className="capa-texto-card-hover">
          <h2 className="title-card-hover">{Tittle}</h2>
          <h4 className="text-card-hover">{language === "es" ? subtittle : subtittle}</h4>
          {/* <ul className="list-card-hover">
            {Object.keys(Content).map((key, index) => (
              <Row key={index}>
                <strong>{key}:</strong>
                <p>{Content[key]}</p>
              </Row>
            ))}
          </ul> */}
        </div>
      </div>
    </Container>
    
  );
}
