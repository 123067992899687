import React, { createContext, useState, useContext, useEffect } from "react";

// Contexto de idioma
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("es"); // Idioma predeterminado

  // Detectar idioma basado en la ubicación o configuración del navegador
  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage; // Detectar idioma del navegador
    if (userLang.startsWith("en")) {
      setLanguage("en");
    } else {
      setLanguage("es");
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useLanguage = () => useContext(LanguageContext);
