import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./DivisionT.css";
import Header from "../../components/Header/Header";
import DivTextildata from "../../assets/scripts/scripts.jsx";
import CardsHover from "../../components/Cards/CardsHover/CardsHover.jsx";
import InfoContact from "../../components/Forms/ContactInfo.jsx";
import Navigate from "../../components/Navigate/Navigate.jsx";
import ContainerBg from "../../components/ContainerBg/ContainerBg.jsx";
import { useLanguage } from "../../assets/translations/Traslations.jsx";

const {
  HeaderDivTextil,
  BgDivTextInfoContact,
  Section1DivTextil,
  Section2DivTextil,
} = DivTextildata;

export default function DivisionT() {
  const { language } = useLanguage();

  const translations = (data) => {
    return data[language];
  };

  function bgCol(bg) {
    const Fondo = bg;
    return {
      backgroundImage: `url(${Fondo})`,
    };
  }
  return (
    <>
      <Navigate />
      <Header
        Banner={translations(HeaderDivTextil).Banner}
        Texto={translations(HeaderDivTextil).Text}
        Subtitulo={translations(HeaderDivTextil).Subtittle}
        Descripcion={translations(HeaderDivTextil).Description}
        TextButton1={translations(HeaderDivTextil).TextButton1}
        navigate1={translations(HeaderDivTextil).Navigate1}
        Button1Classname={translations(HeaderDivTextil).Button1Classname}
        Button2Classname={translations(HeaderDivTextil).Button2Classname}
      />

      {/* SECTION 1 */}
      <section>
        <div className="bgDivisionTextil overflow-hidden">
          <div className="filterDivText"></div>
          <Container fluid={true}>
            <h2 className="TittleSection1DivisionT">
              {translations(Section1DivTextil).tittle}
            </h2>
            <Row>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={translations(Section1DivTextil).Card1.TelaBg}
                  Tittle={translations(Section1DivTextil).Card1.NombreTela}
                  Content={translations(Section1DivTextil).Card1.Propiedades}
                  subtittle={translations(Section1DivTextil).subtittle}
                  navigateto={translations(Section1DivTextil).navigate}
                />
              </Col>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={translations(Section1DivTextil).Card2.TelaBg}
                  Tittle={translations(Section1DivTextil).Card2.NombreTela}
                  Content={translations(Section1DivTextil).Card2.Propiedades}
                  subtittle={translations(Section1DivTextil).subtittle}
                  navigateto={translations(Section1DivTextil).navigate}
                />
              </Col>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={translations(Section1DivTextil).Card3.TelaBg}
                  Tittle={translations(Section1DivTextil).Card3.NombreTela}
                  Content={translations(Section1DivTextil).Card3.Propiedades}
                  subtittle={translations(Section1DivTextil).subtittle}
                  navigateto={translations(Section1DivTextil).navigate}
                />
              </Col>
            </Row>
            <Row>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={translations(Section1DivTextil).Card4.TelaBg}
                  Tittle={translations(Section1DivTextil).Card4.NombreTela}
                  Content={translations(Section1DivTextil).Card4.Propiedades}
                  subtittle={translations(Section1DivTextil).subtittle}
                  navigateto={translations(Section1DivTextil).navigate}
                />
              </Col>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={translations(Section1DivTextil).Card5.TelaBg}
                  Tittle={translations(Section1DivTextil).Card5.NombreTela}
                  Content={translations(Section1DivTextil).Card5.Propiedades}
                  subtittle={translations(Section1DivTextil).subtittle}
                  navigateto={translations(Section1DivTextil).navigate}
                />
              </Col>
              <Col sx={12} md={4}>
                <CardsHover
                  Img={translations(Section1DivTextil).Card6.TelaBg}
                  Tittle={translations(Section1DivTextil).Card6.NombreTela}
                  Content={translations(Section1DivTextil).Card6.Propiedades}
                  subtittle={translations(Section1DivTextil).subtittle}
                  navigateto={translations(Section1DivTextil).navigate}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* SECTION2 */}

      <section>
        <ContainerBg Bg={Section2DivTextil.Img} hg={"100vh"} hgMobile={"100vh"}>
          <div className="filterSection2PaqCom"></div>
          <Row className="TittleSection2PaqCom">
            <Col xs={12} md={12}>
              <h2>{translations(Section2DivTextil).tittle}</h2>
            </Col>
          </Row>

          <Row className="Row1Section2PaqCom">
            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(translations(Section2DivTextil).Row2.col1.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{translations(Section2DivTextil).Row2.col1.text}</h3>
              </div>
            </Col>

            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(translations(Section2DivTextil).Row2.col2.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{translations(Section2DivTextil).Row2.col2.text}</h3>
              </div>
            </Col>

            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(translations(Section2DivTextil).Row2.col3.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{translations(Section2DivTextil).Row2.col3.text}</h3>
              </div>
            </Col>
            <Col xs={12} md={3} className="ColSection2PaqCom">
              <div
                className="ImgBgColPaqCom"
                style={bgCol(translations(Section2DivTextil).Row2.col4.img)}
                alt="ImgBgCol"
              />
              <div className="CapaTextoSection2PaqCom">
                <h3>{translations(Section2DivTextil).Row2.col4.text}</h3>
              </div>
            </Col>
          </Row>
        </ContainerBg>
      </section>
      {/* SECTION 3 */}
      {/* ALGUNOS DE NUESTROS CLIENTES"!!! */}
      {/* <section>
        <ContainerBg Bg={Section3DivTextil.Img} hg={"100vh"} hgMobile={"250vh"}>
          <div className="filter1DivTSection3"></div>
          <Row className="TittleSection3DivT">
            <Col xs={12} md={12}>
              <h2>{Section3DivTextil.Tittle}</h2>
            </Col>
          </Row>
          <Row className="Row1Section3DivT">
            <Col xs={12} md={12} className="ColSection3DivT">
              <p>{Section3DivTextil.P1}</p>
            </Col>
          </Row>

        </ContainerBg>
      </section> */}
      {/* SECTION 4 */}
      <section id="Forms">
        <InfoContact Fondo={BgDivTextInfoContact.fondo} />
      </section>
    </>
  );
}
