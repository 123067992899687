import React from "react";
import { Container } from "reactstrap";
import "./CardsInfo.css";
import { useLanguage } from "../../../assets/translations/Traslations.jsx"; // Importa el contexto



export default function CardsInfo({ contentFront, contentBack, icon }) {
  const { language } = useLanguage(); // Obtén el idioma actual


  return (
    <Container className="ContainerCardInfo">
      <div className="cardInfo">
        <div className="icon">
          <img src={icon} alt="icon" />
        </div>
        <strong>{contentFront}</strong>
        <div className="card__body">{language === "es" ? "Conocer más..." : "More information..."}</div>
        <span>{contentBack}</span>
      </div>
    </Container>
  );
}
